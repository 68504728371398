import PropTypes from 'prop-types';

import {Component, createRef, RefObject} from 'react'
import './Map.css'
import {debounce} from "./utils";
import * as _ from "lodash"

interface MapProps {
    zoom: number,
    center: woosmap.map.LatLngLiteral,
    styles?: woosmap.map.MapStyleSpec[],
    onFeatureSelect?: Function,
    isGoogleMapVisible?: boolean
    onZoomChanged?: (zoom: number) => void;
    onCenterChanged?: (center: woosmap.map.LatLngLiteral) => void
}

export default class Map extends Component<MapProps> {
    static propTypes = {
        zoom: PropTypes.number.isRequired,
        center: PropTypes.exact({lat: PropTypes.number, lng: PropTypes.number}).isRequired,
        styles: PropTypes.array,
        onFeatureSelect: PropTypes.func,
        isGoogleMapVisible: PropTypes.bool
    }

    _mapRef: RefObject<HTMLDivElement>
    _map: woosmap.map.Map;

    constructor(props) {
        super(props);
        this._mapRef = createRef();
    }

    componentDidMount() {

        // @ts-ignore
        woosmap.map.config.flags.set('map_feature_debug')

        this._map = new woosmap.map.Map(this._mapRef.current, {
            center: this.props.center,
            zoom: this.props.zoom - 1,
            gestureHandling: 'auto',
            styles: this.props.styles,
            defaultStyle: "streets"
        })
        /*
        woosmap.map.event.addListenerOnce(this._map, "idle", () => {

            this._map._style.addSource("dem", {
                "type": "raster-dem",
                "tiles": ["https://s3.amazonaws.com/elevation-tiles-prod/terrarium/{z}/{x}/{y}.png"],
                "format": "terrarium"
            })
            this._map._style.addLayer({
                "id": "hillshade",
                "source": "dem",
                "type": "hillshade",
                "paint": {
                    "hillshade-accent-color": "rgba(0,0,0,0.05)",
                    "hillshade-highlight-color": "rgba(255, 255, 255, 0.1)",
                    "hillshade-shadow-color": "rgba(0,0,0,0.05)",

                }
            }, "water")
        })*/

        woosmap.map.event.addListener(this._map, 'map_feature_debug', (feature) => {
            console.log(feature);
            this.props.onFeatureSelect(feature)
        });
        let changesPending = false;
        woosmap.map.event.addListener(this._map, 'zoom_changed', debounce(() => {
            changesPending = true;
        }, 200));

        woosmap.map.event.addListener(this._map, 'center_changed', debounce(() => {
            changesPending = true;
        }, 200));

        woosmap.map.event.addListener(this._map, 'idle', debounce(() => {
            if (changesPending) {
                let newZoom = this._map.getZoom()
                let newCenter = this._map.getCenter().toJSON()
                changesPending = false
                setTimeout(() => {
                    this.props.onZoomChanged(newZoom);
                    this.props.onCenterChanged(newCenter)
                }, 0)
            }
        }, 200));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let center = this._map.getCenter();
        if (center.lat() != this.props.center.lat && center.lng() != this.props.center.lng) {
            this._map.setCenter(this.props.center);
        }

        let zoom = this._map.getZoom();
        if (zoom != this.props.zoom) {
            this._map.setZoom(this.props.zoom);
        }

        if (prevProps.styles != null || !_.isEqual(prevProps.styles, this.props.styles)) {
            this._map.set('styles', this.props.styles);
        }
    }

    render() {
        return (<div className={"Map-container"}>
            <div ref={this._mapRef} className="Map-div"/>
        </div>);
    }
}

