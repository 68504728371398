import React from 'react';
import {createRoot} from 'react-dom/client';


import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import {getParamsFromURL} from "./utils";
import {LZString} from "./lz-string";
import {debounce} from "./utils";


(function () {
    var testData = [{
        "elementType": "geometry",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "elementType": "labels.icon",
        "stylers": [{
            "visibility": "off"
        }]
    }, {
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#616161"
        }]
    }, {
        "elementType": "labels.text.stroke",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#bdbdbd"
        }]
    }, {
        "featureType": "administrative.locality",
        "stylers": [{
            "visibility": "simplified"
        }]
    }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
            "color": "#eeeeee"
        }]
    }, {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#757575"
        }]
    }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{
            "color": "#D5EFE1"
        }]
    }, {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
            "color": "#ffffff"
        }]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#757575"
        }]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{
            "color": "#dadada"
        }]
    }, {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#616161"
        }]
    }, {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }, {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{
            "color": "#e5e5e5"
        }]
    }, {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{
            "color": "#eeeeee"
        }]
    }, {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#C4D5E9"
        }]
    }, {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }, {
        "elementType": "geometry",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "elementType": "labels.icon",
        "stylers": [{
            "visibility": "off"
        }]
    }, {
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#616161"
        }]
    }, {
        "elementType": "labels.text.stroke",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#bdbdbd"
        }]
    }, {
        "featureType": "administrative.locality",
        "stylers": [{
            "visibility": "simplified"
        }]
    }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
            "color": "#eeeeee"
        }]
    }, {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#757575"
        }]
    }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{
            "color": "#D5EFE1"
        }]
    }, {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
            "color": "#ffffff"
        }]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#757575"
        }]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{
            "color": "#dadada"
        }]
    }, {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#616161"
        }]
    }, {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }, {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{
            "color": "#e5e5e5"
        }]
    }, {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{
            "color": "#eeeeee"
        }]
    }, {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
            "color": "#f5f5f5"
        }]
    }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#C4D5E9"
        }]
    }, {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#9e9e9e"
        }]
    }];
    // let compressed = LZString.compressToBase64(JSON.stringify(testData));
    // console.log(compressed)
    // /*global bota*/
    // console.log(btoa(JSON.stringify(testData)));
    // console.log(LZString.decompressFromBase64(compressed))
    // console.log('wheeee');
}());


function dumpUpdate(data) {
    let url = new URL(window.location.href);
    console.log(data);
    if (data) {
        if (data.styles) {
            url.searchParams.set('styles', data.styles);
        } else {
            url.searchParams.delete('styles');
        }

        if (data.featureType) {
            url.searchParams.set('feature-type', data.featureType)
        } else {
            url.searchParams.delete('feature-type')
        }

        if (data.elementType) {
            url.searchParams.set('element-type', data.elementType)
        } else {
            url.searchParams.delete('element-type')
        }

        if (data.lat) {
            url.searchParams.set('lat', data.lat);
        }

        if (data.lng) {
            url.searchParams.set('lng', data.lng)
        }

        if (data.zoom) {
            url.searchParams.set('zoom', data.zoom)
        }
    }

    window.history.pushState(data, window.location.href, url.toString());
}

let urlParams = getParamsFromURL(window.location.href);


// if (urlParams.lat && urlParams.lng) {
//     center = {lat: parseFloat(urlParams.lat), lng: parseFloat(urlParams.lng)}
// }

let url = new URL(window.location.href);
let urlStyles = url.searchParams.get('styles');
let featureType = url.searchParams.get('feature-type');
let elementType = url.searchParams.get('element-type');

let lat = url.searchParams.get('lat');
let lng = url.searchParams.get('lng');
let center = {lat: 43.6, lng: 3.883}

if (lat !== null && lng !== null) {
    center = {lat: parseFloat(lat), lng: parseFloat(lng)};
}

let urlZoom = url.searchParams.get('zoom');
let zoom = 8

if (urlZoom != null) {
    zoom = parseFloat(urlZoom) + 1;
}

let debouncedUpdate = debounce(dumpUpdate, 1000);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

let app: App | void;

window.addEventListener("popstate", function (event) {
    if (app) {
        if (Object.keys(event.state).length === 0 && event.state.constructor === Object) {
            app.setState({
                featureTypeSelected: null,
                elementTypeSelected: null,
                zoom: 8,
                center: {lat: 43.3, lng: 3.883},
                styles: [],
            });
        } else {
            const state = {...event.state}

            if (event.state.styles) {
                const decoded = LZString.decompressFromBase64(event.state.styles);
                state.styles = JSON.parse(decoded);
            } else {
                state.styles = [];
            }
            app.styleReducer.load(state.styles);
            app.setState(state)
        }
    }
});


root.render(
    <App ref={el => app = el} featureType={featureType} elementType={elementType} styles={urlStyles}
         onUpdateUrl={(data) => dumpUpdate(data)} center={center}
         zoom={zoom}/>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
