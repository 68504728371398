import PropTypes from 'prop-types'
import {Component, createRef} from 'react'
import './Stylesheet.css'
import {debounce} from './utils'

interface MultipleRadioProps {
    onChange: (event: { key: string, value: string }) => void;
    choices: Array<{ key: string, label: string }>;
    name: string;
    value: string;

}

class MultipleRadio extends Component<MultipleRadioProps> {
    onChange(e) {
        this.props.onChange({key: e.target.name, value: e.target.value});
    }

    render() {
        let inputs = [];
        for (let choice of this.props.choices) {
            let radioId = 'MultipleRadio_' + this.props.name + '_' + choice.key;
            inputs.push(<div key={this.props.name + '_' + choice.key}>
                <input id={radioId} name={this.props.name}
                       onChange={this.onChange.bind(this)}
                       value={choice.key}
                       checked={choice.key === this.props.value}
                       type={'radio'}/><label htmlFor={radioId}>{choice.label}</label>
            </div>)
        }
        return (<div>{inputs}</div>);
    }
}

/*
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state {
            value: props.value;
        }

        this.inputRef = createRef()
    }


    render() {
        return (<div className={'Stylesheet-input-block'}>
            <label className={classNameFor(this.state.value)}
                   htmlFor={'saturation'}>Saturation {this.state.value}</label>
            <input ref={this.inputRef}/>
        </div>);
        return (<div></div>);
    }
}
*/

interface StyleSheetProps {
    onToggle: (style: Array<woosmap.map.MapStyleSpec>) => void
    onStyle: (style: woosmap.map.MapStyleSpec) => void
    featureType: string
    elementType: string

    style: woosmap.map.MapStyler;
}



export default class Stylesheet extends Component<StyleSheetProps> {
    static propTypes = {
        onToggle: PropTypes.func
    }

    render() {
        let onChange = (e) => {
            let styler: woosmap.map.MapStyleSpec = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {color: e.target.value}
                ]
            }
            this.props.onStyle(styler);
        };

        let onHueChange = (e) => {
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {hue: e.target.value}
                ]
            }
            this.props.onStyle(styler);
        }


        let onVisibilityChange = (data) => {
            if (data.value !== 'inherit') {
                let styler = {
                    featureType: this.props.featureType,
                    elementType: this.props.elementType,
                    stylers: [
                        {visibility: data.value}
                    ]
                }
                this.props.onStyle(styler);
            }
        }

        let onSaturationChange = (e) => {
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {saturation: parseInt(e.target.value)}
                ]
            }
            this.props.onStyle(styler);
        }

        let onLightnessChange = (e) => {
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {lightness: parseInt(e.target.value)}
                ]
            }
            this.props.onStyle(styler);
        }

        let onWeightChange = (e) => {
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {weight: parseFloat(e.target.value)}
                ]
            }
            this.props.onStyle(styler);
        }

        let onGammaChange = (e) => {
            let newGamma = parseFloat(e.target.value);
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {gamma: newGamma}
                ]
            }

            this.props.onStyle(styler);
        };

        let onInvertLightnessChange = (e) => {
            let checked = e.target.checked;
            let styler = {
                featureType: this.props.featureType,
                elementType: this.props.elementType,
                stylers: [
                    {invert_lightness: checked}
                ]
            }

            this.props.onStyle(styler);
        };


        let visibility = this.props.style.visibility || 'inherit';
        let color = this.props.style.color || '#FFFF00';
        let hue = this.props.style.hue || '#FFFF00';
        let saturation = (this.props.style.saturation || 0).toFixed(1);
        let lightness = (this.props.style.lightness || 0).toFixed(1);
        let gamma = (this.props.style.gamma || 1).toFixed(1);
        let weight = (this.props.style.weight || 1).toFixed(1);
        let invertLightness = this.props.style.invert_lightness;

        function classNameFor(prop) {
            return prop !== undefined ? 'Stylesheet-label-changed' : 'Stylesheet-label'
        }

        return (<div className="Stylesheet-div">
            {/* <div>
                <button onClick={this.toggleAubergine.bind(this)}>Toggle aubergine</button>
            </div>*/}
            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.color)} htmlFor={'color'}>Color</label>
                <input name={'color'} onInput={onChange.bind(this)} type={'color'} value={color}/>
            </div>
            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.visibility)} htmlFor={'visibilty'}>Visibility</label>

                <MultipleRadio onChange={onVisibilityChange.bind(this)} name={'visibility'} choices={[
                    {key: 'inherit', label: 'inherit'},
                    {key: 'on', label: 'visible'},
                    {key: 'off', label: 'hidden'}
                ]} value={visibility}/>
            </div>
            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.hue)} htmlFor={'hue'}>Hue {hue}</label>
                <input name={'hue'} onInput={onHueChange.bind(this)} type={'color'} value={hue}/>
            </div>
            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.invert_lightness)}
                       htmlFor={'invert_lightness'}>Invert lightness</label>
                <input onInput={onInvertLightnessChange} type={'checkbox'} name={'invert_lightess'}
                       defaultChecked={invertLightness}/>
            </div>
            {/*<Slider onChange={onSaturationChange} name={'saturation'} title={'Saturation'} value={saturation}}
                    minValue={'100'} step={5}/>*/}
            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.saturation)}
                       htmlFor={'saturation'}>Saturation {saturation}</label>
                <input onInput={onSaturationChange} type={'range'} name={'saturation'} min={'-100'}
                       max={'100'} step={'5'} value={saturation}/>
            </div>

            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.lightness)}
                       htmlFor={'lightness'}>Lightness {lightness}</label>
                <input onInput={onLightnessChange} type={'range'} name={'lightness'} min={'-100'}
                       max={'100'} step={'5'} value={lightness}/>
            </div>

            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.weight)} htmlFor={'weight'}>Weight {weight}</label>
                <input onInput={onWeightChange} type={'range'} name={'weight'} min={'0'}
                       max={'10'} step={'0.25'} value={weight}/>
            </div>

            <div className={'Stylesheet-input-block'}>
                <label className={classNameFor(this.props.style.gamma)} htmlFor={'gamma'}>Gamma {gamma}</label>
                <input onChange={onGammaChange} type={'range'} name={'gamma'} min={'0'}
                       max={'10'} step={'0.1'} value={gamma}/>
            </div>
        </div>)
    }
}
