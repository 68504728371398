import './App.css';
import Map from './Map';
import Editor from './Editor';
import Stylesheet from './Stylesheet';
import React from 'react';
import Tree from './Tree';
import {StyleReducer} from './style-reducer';
import {debounce} from './utils';
import {LZString} from './lz-string';

import {default as POI_TREE} from "./poi.json"
import {default as POINT_OF_INTEREST_TREE} from "./point_of_interest.json"


let elementTypes = [
    {
        key: 'all',
    },
    {
        'key': 'geometry', children: [
            {'key': 'fill'},
            {'key': 'stroke'},
        ],
    },
    {
        'key': 'labels', children: [
            {
                'key': 'icon', children: [
                    {'key': 'fill', 'label': 'casing'},
                    {'key': 'stroke', 'label': 'symbol'},
                ],
            },
            {
                'key': 'text', children: [
                    {'key': 'fill', 'label': 'text'},
                    {'key': 'stroke', 'label': 'outline'},
                ],
            }],
    }];

let featureTypes = [{
    key: 'all',
},
    {
        key: 'water',
    },
    {
        key: 'administrative', children: [
            {key: 'country'},
            {key: 'province'},
            {key: 'locality'},
            {key: 'land_parcel'},
            {key: 'neighborhood'},
        ],
    },
    {
        key: 'landscape', children: [
            {'key': 'man_made', children: [{'key': 'built_up'}]},
            {
                'key': 'natural', children: [
                    {'key': 'terrain'},
                    {'key': 'landcover'},
                    {'key': 'background'}, // children: [{"key":"forest"}, {"key":"bare_sparse_vegetation"}, {"key":"grass"}, {"key":"wood"}, {"key":"ice"}]}
                ],
            },
        ],
    },
    POI_TREE,
    POINT_OF_INTEREST_TREE, {
        key: 'road', children: [
            {key: 'arterial'},
            {key: 'highway', children: [{key: 'controlled_access'}]},
            {key: 'local'},
            {key: 'pedestrian', children: [{key: "aerialway"}]}
        ],
    },
    {key: 'ferry'},
    {
        key: 'transit', children: [
            {key: 'line'},
            {key: 'station', children: [{key: 'airport'}, {key: 'bus'}, {key: 'rail'}]},
        ],
    },
];


interface AppProps {
    featureType: string | null;
    elementType: string | null;
    onUpdateUrl: Function;
    styles: string | null;
    center: woosmap.map.LatLngLiteral;
    zoom: number;
}

interface AppState {
    loadPanel: boolean;
    styles: woosmap.map.MapStyleSpec[];
    featureTypeSelected: string | null;
    elementTypeSelected: string | null;
    modifiedFeatureTypes: string[];
    modifiedElementTypes: string[];
    zoom: number;
    center: woosmap.map.LatLngLiteral;
}

export default class App extends React.Component<AppProps, AppState> {
    styleReducer: StyleReducer;
    updateFunc: Function;
    updateUrl: Function;

    constructor(props) {
        super(props);
        this.styleReducer = new StyleReducer();

        if (props.styles) {
            this.styleReducer.load(JSON.parse(LZString.decompressFromBase64(props.styles)));
        }

        this.state = {
            styles: this.styleReducer._cache,
            featureTypeSelected: this.props.featureType || null,
            elementTypeSelected: this.props.elementType || null,
            modifiedFeatureTypes: this.styleReducer.getModifiedFeatureTypes(),
            modifiedElementTypes: this.styleReducer.getModifiedElementTypes(this.props.featureType),
            loadPanel: false,
            zoom: props.zoom,
            center: props.center,
        };

        this.updateFunc = debounce(this.updateStyle, 200, this);
        this.updateUrl = debounce(this._updateUrl, 1000, this);
    }

    componentDidMount() {
        var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
        var n = 0;
        document.addEventListener('keyup', (e) => {
            // if (e.keyCode === k[n++]) {
            //     if (n === k.length) {
            //         alert('Konami !!!');
            //         n = 0;
            //         return false;
            //     }
            //     e.preventDefault();
            //
            // } else {
            //     n = 0;
            // }
            if (e.key === 'Escape') {
                this.setState({
                    featureTypeSelected: null,
                    elementTypeSelected: null
                });
            } else if (e.key === 'j') {
                this.setState({loadPanel: !this.state.loadPanel});
            } else if (e.key === 'R') {
                this.styleReducer.clear();
                this.setState({
                    featureTypeSelected: null,
                    elementTypeSelected: null,
                    zoom: 8,
                    center: {lat: 43.3, lng: 3.883},
                    styles: [],
                });

                this.updateStyle();

            } else if (e.key === 'r') {
                this.styleReducer.clear();
                this.setState({
                    featureTypeSelected: null,
                    elementTypeSelected: null,
                    styles: [],
                });

                this.updateStyle();
            }
        });
    }

    updateStyle() {
        this.setState({
            styles: window.structuredClone(this.styleReducer._cache),
            modifiedFeatureTypes: this.styleReducer.getModifiedFeatureTypes(),
            modifiedElementTypes: this.styleReducer.getModifiedElementTypes(this.state.featureTypeSelected),
        });
        this.updateUrl();
    }

    _updateUrl() {
        let compressedStyle = null;
        if (this.styleReducer._cache.length > 0) {
            compressedStyle = LZString.compressToBase64(JSON.stringify(this.styleReducer._cache));
        }
        this.props.onUpdateUrl({
            styles: compressedStyle,
            featureType: this.state.featureTypeSelected,
            elementType: this.state.elementTypeSelected,
            lat: this.state.center.lat,
            lng: this.state.center.lng,
            zoom: this.state.zoom,
        });
    }

    render() {
        let onSelect = (feature) => {
            const metadata = feature.layer.metadata || {};

            let elementType = null;
            if ((metadata.elementTypes || []).length === 1) {
                elementType = metadata.elementTypes[0];
            }

            this.setState({
                featureTypeSelected: metadata.featureType,
                elementTypeSelected: elementType,
                modifiedElementTypes: this.styleReducer.getModifiedElementTypes(metadata.featureType),
            });
            this.updateUrl();
        };
        let onSelectFeatureType = (path) => {
            this.setState({
                featureTypeSelected: path,
                modifiedElementTypes: this.styleReducer.getModifiedElementTypes(path),
            });
            this.updateUrl();
        };
        let onSelectElementType = (path) => {
            this.setState({
                elementTypeSelected: path,
            });
            this.updateUrl();
        };

        let addRule = (rule) => {
            this.styleReducer.addRule(rule);
            this.updateFunc();
        };

        let saveStyle = () => {
            let input = document.getElementsByName('inputStyle')[0] as HTMLInputElement;
            this.styleReducer.load(JSON.parse(input.value));
            this.updateFunc();
            this.setState({loadPanel: false});
        };

        let updateZoom = (zoom) => {
            this.setState({zoom: zoom});
            this.updateUrl();
        };
        let updateCenter = (center) => {
            this.setState({center: center});
            this.updateUrl();
        };

        return (
            <div className="App">
                <Map onFeatureSelect={(feature) => onSelect(feature)}
                     onZoomChanged={updateZoom}
                     onCenterChanged={updateCenter}
                     center={this.state.center} zoom={this.state.zoom} styles={this.state.styles}/>

                <Editor>
                    <div className={'App-column'}>
                        <h3>Feature Type</h3>
                        <Tree selected={this.state.featureTypeSelected} modified={this.state.modifiedFeatureTypes}
                              onSelect={onSelectFeatureType} data={featureTypes}/>
                    </div>
                    {
                        this.state.featureTypeSelected ?
                            <div className={'App-column'}>
                                <h3>Element Type</h3>
                                <Tree selected={this.state.elementTypeSelected}
                                      modified={this.state.modifiedElementTypes}
                                      onSelect={onSelectElementType}
                                      data={elementTypes}/></div> : null
                    }
                    {
                        this.state.featureTypeSelected && this.state.elementTypeSelected ?
                            <div className={'App-column'}>
                                <h3>Stylers</h3>
                                <Stylesheet style={this.styleReducer.getFlattenedProps(
                                    this.state.featureTypeSelected,
                                    this.state.elementTypeSelected || 'all')}
                                            elementType={this.state.elementTypeSelected}
                                            featureType={this.state.featureTypeSelected}
                                            onToggle={(styles) => this.setState({styles: styles})}
                                            onStyle={(styler) => addRule(styler)}
                                /></div>
                            : null
                    }
                </Editor>
                {
                    this.state.loadPanel ?
                        <div className={'StyleInput'}>
                        <textarea
                            name={'inputStyle'}
                            defaultValue={this.styleReducer._cache.length > 0 ? JSON.stringify(this.styleReducer._cache, null, 4) : ''}/>
                            <button onClick={() => saveStyle()}>Apply</button>
                        </div>
                        : null
                }

            </div>
        );
    }
}

