export function debounce<T extends (...params: any) => any>(func: T, wait, context = null): (...args: Parameters<T>) => ReturnType<T> {
    let result;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const debounced = (...args: Parameters<T>): ReturnType<T> => {
        const ctx = context || this;
        let later = function (..._: any) {
            timeout = null;
            result = func.apply(ctx, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        return result;
    };
    return debounced as ((...args: Parameters<T>) => ReturnType<T>);
}

export function getParamsFromURL(url) {
    let params = {};

    let urlParts = url.split('?');
    if (urlParts.length > 1) {
        let queryString = urlParts[1];

        let queryPairs = queryString.split('&');

        for (let queryPairIndex = 0; queryPairIndex < queryPairs.length; queryPairIndex++) {
            let queryPair = queryPairs[queryPairIndex];
            if (queryPair.indexOf('=') > -1) {
                let queryPairParts = queryPair.split('=');
                params[decodeURIComponent(queryPairParts[0])] = decodeURIComponent(queryPairParts[1]);
            } else {
                params[queryPair] = '';
            }
        }
    }

    return params;
}

export function buildQueryString(params) {
    let queryStringParts = [];

    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            let value = params[key];
            if (value !== undefined) {
                queryStringParts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            }
        }
    }

    return queryStringParts.join('&');
}
