export class StyleReducer {
    _cache: Array<any>
    modified: Record<string, any>

    constructor() {
        this._cache = [];

        this.modified = {};
    }

    findRule(featureType, elementType) {
        for (let ruleIndex in this._cache) {
            let rule = this._cache[ruleIndex];
            if (rule.featureType === featureType && rule.elementType === elementType) {
                return ruleIndex;
            }
        }

        return -1;
    }

    addRule(rule) {
        let ruleToAdd = rule;
        if (this._cache.length > 0) {
            ruleToAdd.elementType = ruleToAdd.elementType || 'all';
            let ruleIndex = this.findRule(ruleToAdd.featureType, ruleToAdd.elementType);
            if (ruleIndex > -1) {
                let lastRule = this._cache[ruleIndex];
                ruleToAdd = Object.assign({}, lastRule);
                let stylers = ruleToAdd.stylers.concat(rule.stylers)
                let _ = {}

                for (let styler of stylers) {
                    for (let stylerKey of Object.keys(styler)) {
                        _[stylerKey] = styler[stylerKey];
                    }
                }

                let compactedStylers = [];
                for (let key of Object.keys(_)) {
                    let __ = {}
                    __[key] = _[key]
                    compactedStylers.push(__)
                }

                ruleToAdd.stylers = compactedStylers;

                this._cache[ruleIndex] = ruleToAdd;
                return;
            }
        }
        this.modified[rule.featureType] = this.modified[rule.featureType] || []
        let elementType = rule.elementType

        if (this.modified[rule.featureType].indexOf(elementType) === -1) {
            this.modified[rule.featureType].push(elementType)
        }
        this._cache.push(ruleToAdd);
    }

    getModifiedFeatureTypes() {
        return Object.keys(this.modified);
    }

    getModifiedElementTypes(featureType) {
        if (featureType) {
            return this.modified[featureType] || []
        }

        return [];
    }

    getFlattenedProps(featureType, elementType) {
        let values = {}
        for (let rule of this._cache) {
            if (rule.featureType === featureType && rule.elementType === elementType) {
                for (let styler of rule.stylers) {
                    for (let key of Object.keys(styler)) {
                        values[key] = styler[key];
                    }
                }
            }
        }

        return values;
    }

    clear() {
        this._cache = [];
        this.modified = {};
    }

    load(rules) {
        this.clear();
        for (let rule of rules) {
            this.addRule(rule);
        }
    }
}
