import React from "react";
import './Tree.css'

function makeNamespace(elements) {
    return elements.filter(e => e !== undefined).join('.')
}

function hasModifications(types, type) {
    for (let modified of types) {
        if (modified.startsWith(type + ".") || type === modified) {
            return true;
        }
    }
    return false;
}


interface TreeProps {
    data?: any
    namespace?: string
    selected: string
    modified: string[]
    onSelect: (path: string | null) => void
}

export default class Tree extends React.Component<TreeProps> {

    getTreeChildren() {
        let nodes = [];
        for (let element of this.props.data) {
            let namespace = makeNamespace([this.props.namespace, element.key]);
            let className = 'Tree-element';
            if (namespace.indexOf('.') === -1) {
                className += ' Tree-element-root'
            }
            className += this.props.selected === namespace ? ' Tree-element-selected' : ''
            let modified = hasModifications(this.props.modified, namespace);
            let treeElement = (<div key={namespace} className={className} role="button" onClick={() => {
                if (this.props.selected === namespace) {
                    this.props.onSelect(null)
                } else {
                    this.props.onSelect(namespace)
                }
            }}><span key={namespace + "_modified"} className={modified ? 'Tree-element-modified' : ''}/>{element.label || element.key}</div>)
            nodes.push(treeElement);

            if (element.children) {
                let subTree = (
                    <Tree key={namespace + "_subtree"} modified={this.props.modified} selected={this.props.selected} onSelect={this.props.onSelect}
                          data={element.children} namespace={namespace}/>);
                nodes.push(subTree);
            }
        }
        return nodes;
    }

    render() {
        return (<div key={this.props.namespace + "_tree"} style={{paddingLeft: '20px'}}>
            {this.getTreeChildren()}
        </div>);
    }
}

