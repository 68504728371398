import {Component} from 'react';
import './Editor.css';


interface EditorProps {
    children: any
}
export default class Editor extends Component<EditorProps> {
    render() {
        return (<div className="Editor-div"> {this.props.children} </div>)
    }
}
